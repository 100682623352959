import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { RecoilRoot } from 'recoil';
import { BrowserRouter as Router } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import useLocalStorage from 'react-use-localstorage';
import OneSignal, { useOneSignalSetup } from 'react-onesignal';
import createClient from './apollo';
import { TOKEN } from './constants/session';
import favicon from './assets/favicon.svg';
import 'tippy.js/dist/tippy.css';
import './App.scss';
import 'react-slidedown/lib/slidedown.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import Imagen from './components/Imagen';

const App = () => {
  const [token] = useLocalStorage(TOKEN);

  useEffect(() => {
    OneSignal.initialize('3835898e-d115-4c01-9ae1-a560739165c1', {
      allowLocalhostAsSecureOrigin: true,
      autoResubscribe: true,
      notifyButton: {
        enable: true,
      },
    });
  }, []);

  useOneSignalSetup(() => {
    // noinspection JSIgnoredPromiseFromCall
    OneSignal.registerForPushNotifications();
  });

  return (
    <Router>
      <Helmet defaultTitle="Imagen Insights">
        <link
          href={favicon}
          rel="icon"
          type="image/svg"
        />
      </Helmet>
      <ApolloProvider client={createClient(token)}>
        <RecoilRoot>
          <Imagen />
        </RecoilRoot>
      </ApolloProvider>
    </Router>
  );
};

export default App;
