import { Form } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import LoginForm from './LoginForm/LoginForm';
import UserService from '../../service/user';

const ForgotPassword = () => {
  const { formatMessage } = useIntl();
  const [email, setEmail] = useState('');
  const [error, setError] = useState(false);
  const [sent, setSent] = useState(false);
  const { userType: type } = useParams();

  const [
    requestPasswordResetEmail,
    { loading },
  ] = useMutation(UserService.requestPasswordResetEmail);

  const onChange = ({ target: { value } }) => setEmail(value);

  const onSubmit = async (event) => {
    event.preventDefault();
    setError(false);
    try {
      const { data: { forgotPassword: { success } } } = await requestPasswordResetEmail({
        variables: { email, type },
      });
      if (success) {
        setSent(true);
      } else {
        setError(true);
      }
    } catch (e) {
      setError(true);
    }
  };

  return (
    sent
      ? (
        <LoginForm
          titleMessage="auth.forgot.successTitle"
          bodyMessage="auth.forgot.successSubtitle"
        />
      )
      : (
        <LoginForm
          onClick={onSubmit}
          submitMessage="auth.forgot.submit"
          bodyMessage={error ? 'auth.forgot.errorSubtitle' : 'auth.forgot.subtitle'}
          titleMessage={error ? 'auth.forgot.errorTitle' : 'auth.forgot.title'}
          className={error && 'text-danger'}
          isDisabled={email === ''}
          isLoading={loading}
        >
          <Form.Control
            className="w-100 my-2"
            placeholder={formatMessage({ id: 'auth.forgot.email' })}
            value={email}
            onChange={onChange}
          />
        </LoginForm>
      )
  );
};

export default ForgotPassword;
