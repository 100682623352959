export const filterKeys = (object, keys) => Object
  .entries(object)
  .reduce((filtered, [key, value]) => (
    keys.includes(key)
      && value !== ''
      && value !== null
      ? {
        ...filtered,
        [key]: value,
      }
      : filtered
  ), {});

export const mergeAndDedupeRefsById = (
  existing = [],
  incoming,
  { readField, args }
) => (
  args?.input?.filter?.search === ''
    ? [...existing, ...incoming]
      .filter((item, i, self) => i === self
        .findIndex((ref) => readField('id', ref) === readField('id', item)))
    : incoming
);
