/* eslint-disable react/display-name */
import { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import Nav from '../Nav/Nav';
import styles from './Landing.module.scss';
import { ReactComponent as GlobeIcon } from '../../../assets/globe.svg';
import { ReactComponent as DollarIcon } from '../../../assets/dollar.svg';
import { ReactComponent as GroupIcon } from '../../../assets/group.svg';
import { ReactComponent as SmileIcon } from '../../../assets/smile.svg';
import { ConsultantPaths as Paths } from '../../../constants/paths';
import Button, { LIGHT } from '../../common/Button/Button';

const Landing = () => (
  <Fragment>
    <Nav showBack={false} />
    <div className="container text-white">
      <div className="row">
        <div className="col-md-6">
          <FormattedMessage
            id="landing.heading"
            values={{
              big: (s) => (
                <span className={`${styles.heading} font-weight-700`}>
                  {s}
                </span>
              ),
              linebreak: <br />,
            }}
          />
          {[GlobeIcon, DollarIcon, GroupIcon, SmileIcon].map((Icon, i) => (
            // eslint-disable-next-line react/jsx-key
            <div className="my-4 d-flex">
              <div className={`${styles.icon} d-flex justify-content-center`}>
                <Icon />
              </div>
              <div className="ml-2">
                <FormattedMessage
                  id={`landing.promo${i + 1}`}
                  values={{
                    bold: (s) => (
                      <span className="font-weight-600">
                        {s}
                      </span>
                    ),
                  }}
                />
              </div>
            </div>
          ))}
          <Link
            to={Paths.createAccount()}
            className="text-decoration-none"
          >
            <Button
              variant={LIGHT}
              className="w-100 font-size-18 justify-content-center py-3 space-letters font-weight-500"
            >
              <FormattedMessage id="landing.createAccount" />
            </Button>
          </Link>
        </div>
      </div>
    </div>
  </Fragment>
);

export default Landing;
