import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ReactComponent as ImagenLogo } from '../../../../assets/imagen.svg';
import styles from './TopNav.module.scss';
import { ClientPaths as Paths } from '../../../../constants/paths';

export const NORMAL = 'normal';
export const MINIMAL = 'minimal';
export const MINIMAL_DARK = 'minimal-dark';
export const DARK = 'dark';

const TopNav = ({ isAdmin, right, variant }) => (
  <div
    className={`${styles.nav} ${styles[variant]} container-fluid d-flex align-items-center justify-content-between`}
  >
    <Link
      className="link-reset ml-4 d-flex align-items-stretch"
      to={isAdmin ? Paths.admin() : Paths.dashboard()}
    >
      <ImagenLogo className={`${styles.logo} my-1`} />
      <div className={`${styles.verticalRule} mx-3`} />
      <div className={`${styles.wordmark} text-uppercase font-weight-500 d-flex align-items-center`}>
        <FormattedMessage id={isAdmin ? 'admin.title' : 'nav.title'} />
      </div>
    </Link>
    {right}
  </div>
);

TopNav.propTypes = {
  isAdmin: PropTypes.bool,
  right: PropTypes.oneOfType([PropTypes.node, PropTypes.bool]),
  variant: PropTypes.oneOf([NORMAL, MINIMAL, MINIMAL_DARK, DARK]),
};

TopNav.defaultProps = {
  isAdmin: false,
  right: null,
  variant: NORMAL,
};

export default TopNav;
