import { Spinner } from 'react-bootstrap';
import PropTypes from 'prop-types';

const Loader = ({ className }) => (
  <div className={`h-100 d-flex align-items-center justify-content-center ${className}`}>
    <Spinner
      className="text-primary"
      animation="border"
    />
  </div>
);

Loader.propTypes = {
  className: PropTypes.string,
};

Loader.defaultProps = {
  className: '',
};

export default Loader;
