import { gql } from '@apollo/client';

const logIn = gql`
  mutation ($email: String!, $password: String!) {
    loginAdmin (input: {
      email: $email,
      password: $password,
    }) {
      jwt,
      user {
        id,
      },
    }
  }
`;

const statistics = gql`
  query ($since: String) {
    adminStatistics (input: {
      since: $since,
    }) {
      totalBrands,
      totalConsultants,
      totalLiveBriefs,
    }
  }
`;

export default {
  logIn,
  statistics,
};
