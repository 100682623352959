import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import ConsultantService from '../../../../service/consultant';
import useSession from '../../../../hooks/useSession';
import NavButton from '../../../common/NavButton/NavButton';
import ProfileImage from '../../../common/ProfileImage';

const ProfileIcon = ({ isActive }) => {
  const { userId: consultantId } = useSession();
  const { loading, error, data } = useQuery(ConsultantService.get, {
    variables: { consultantId },
  });

  if (loading || error) {
    return <div className="mx-3" />;
  }

  const {
    consultant: {
      user: {
        firstName,
        lastName,
        profileImage,
      },
    },
  } = data;

  return (
    <NavButton
      path=""
      isCurrent={isActive}
    >
      <ProfileImage
        firstName={firstName}
        lastName={lastName}
        src={profileImage}
      />
    </NavButton>
  );
};

ProfileIcon.propTypes = {
  isActive: PropTypes.bool.isRequired,
};

export default ProfileIcon;
