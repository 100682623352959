/* eslint-disable react/display-name */
import { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import Nav from '../Nav/Nav';
import styles from './Confirmation.module.scss';
import { arrayOf } from '../../../utils/array';

const Confirmation = () => (
  <Fragment>
    <Nav showBack={false} />
    <div className="container text-white">
      <div className="row">
        <div className="col-md-6">
          <div className={`${styles.heading} font-weight-700`}>
            <FormattedMessage id="confirmation.heading" />
          </div>
          {arrayOf(3).map((_, i) => (
            // eslint-disable-next-line react/jsx-key
            <div className="my-4 d-flex">
              <div className="ml-2">
                <FormattedMessage id={`confirmation.body${i + 1}`} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  </Fragment>
);

export default Confirmation;
