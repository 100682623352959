/* eslint-disable react/jsx-props-no-spreading */
import { Button as BootstrapButton, Spinner } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { Fragment } from 'react';
import classNames from 'classnames';
import styles from './Button.module.scss';

export const PRIMARY = 'primary';
export const LIGHT = 'light';
export const LIGHT_ALT = 'lightAlt';
export const DANGER = 'danger';
export const OUTLINE = 'outline';
export const TRANSLUCENT = 'translucent';

const Button = ({
  variant, className, isLoading, children, isIconOnly, ...props
}) => (
  <BootstrapButton
    className={classNames(styles.button, styles[`button-${variant}`], 'font-size-14 font-weight-500 d-flex align-items-center', {
      'px-2': isLoading || isIconOnly,
      'px-3': !isLoading && !isIconOnly,
      [styles.softDisable]: isLoading,
    }, className)}
    {...props}
  >
    {
      isLoading
        ? (
          <Fragment>
            &nbsp;
            <Spinner
              animation="border"
              size="sm"
            />
            &nbsp;
          </Fragment>
        )
        : children
    }
  </BootstrapButton>
);

Button.propTypes = {
  variant: PropTypes.oneOf([PRIMARY, LIGHT, LIGHT_ALT, DANGER, OUTLINE, TRANSLUCENT]),
  className: PropTypes.string,
  isLoading: PropTypes.bool,
  children: PropTypes.node.isRequired,
  isIconOnly: PropTypes.bool,
};

Button.defaultProps = {
  variant: PRIMARY,
  className: '',
  isLoading: false,
  isIconOnly: false,
};

export default Button;
