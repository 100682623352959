import OneSignal from 'react-onesignal';

const logOut = () => {
  // noinspection JSIgnoredPromiseFromCall
  OneSignal.sendTag('consultantId', '');
  window.localStorage.clear();
  window.sessionStorage.clear();
  window.location.reload();
};

export default logOut;
