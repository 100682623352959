import useLocalStorage from 'react-use-localstorage';
import { TOKEN } from '../constants/session';
import useSession from './useSession';
import { UserType } from '../constants/userType';

const isFalsey = (value) => !value || value === 'null';

const useAuthentication = () => {
  const [token] = useLocalStorage(TOKEN);
  const { userId, userType } = useSession();
  return (
    !(isFalsey(token)
    || isFalsey(userId))
    || Object.values(UserType).includes(userType)
  );
};

export default useAuthentication;
