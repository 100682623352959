import { LOCALES } from '../constants/locale';

export const flattenLangpack = (nested, prefix = '') => Object
  .keys(nested)
  .reduce((flattened, key) => {
    const value = nested[key];
    const prefixedKey = prefix
      ? `${prefix}.${key}`
      : key;

    return typeof value === 'string'
      ? {
        ...flattened,
        [prefixedKey]: value,
      }
      : {
        ...flattened,
        ...flattenLangpack(value, prefixedKey),
      };
  }, {});

export default LOCALES.reduce((langpackLoader, locale) => ({
  ...langpackLoader,
  // eslint-disable-next-line prefer-template
  [locale]: () => import('../locale/' + locale + '.json'),
}), {});
