import { gql } from '@apollo/client';
import { mergeAndDedupeRefsById } from '../utils/object';

export const insightsTypePolicy = {
  keyArgs: false,
  merge: mergeAndDedupeRefsById,
  read: (insights, { args, readField }) => {
    const byBrief = args?.input?.filter?.briefId;
    if (insights === undefined) {
      return insights;
    }
    if (byBrief !== undefined) {
      return insights.filter((insight) => readField('briefId', insight) === byBrief);
    }
    return insights;
  },
};

const listByConsultant = gql`
  query (
    $limit: Int!,
    $consultantId: String!,
  ) {
    insights (input: {
      consultantId: $consultantId,
      pagination: {
        limit: $limit,
        offset: 0,
      },
    }) {
      id,
      brief {
        brand {
          name,
          logoImage,
        }
      },
      answers {
        id,
        text,
        filename,
        question {
          index,
          title,
        },
      },
    }
  }
`;

const create = gql`
  mutation (
    $answers: [AddInsightAnswerInput!]!,
    $briefId: String!,
    $consultantId: String!,
  ) {
    addInsight(input: {
      briefId: $briefId,
      consultantId: $consultantId,
      answers: $answers,
    }) {
      id,
    },
  }
`;

const listByBriefIdForClient = gql`
  query (
    $briefId: String!,
    $clientId: String!,
    $limit: Int!,
    $offset: Int!,
  ) {
    insights(input: {
      clientId: $clientId,
      filter: {
        briefId: $briefId,
      },
      pagination: {
        limit: $limit,
        offset: $offset,
      },
    }) {
      id,
      createdAt,
      status,
      unread,
      briefId,
      answers {
        id,
        filename,
        text,
        question {
          title,
          index,
        },
      },
      consultant {
        id,
        address {
          address,
        },
        gender {
          title,
        },
        dateOfBirth,
      },
    },
  }
`;

const listByBriefIdForAdmin = gql`
  query (
    $briefId: String!,
    $clientId: String!,
    $limit: Int!,
    $offset: Int!,
  ) {
    insights(input: {
      clientId: $clientId,
      filter: {
        briefId: $briefId,
      },
      pagination: {
        limit: $limit,
        offset: $offset,
      },
    }) {
      id,
      createdAt,
      status,
      unread,
      briefId,
      answers {
        id,
        filename,
        text,
        question {
          title,
          index,
        },
      },
      consultant {
        id,
        address {
          address,
        },
        user {
          firstName,
          lastName,
          email,
        },
        gender {
          title,
        },
        dateOfBirth,
      },
    },
  }
`;

export default {
  listByConsultant,
  create,
  listByBriefIdForClient,
  listByBriefIdForAdmin,
};
