import PropTypes from 'prop-types';
import ImageIcon, { CIRCLE } from './ImageIcon/ImageIcon';

const ProfileImage = ({ firstName, lastName, src }) => (
  <ImageIcon
    variant={CIRCLE}
    className="imagen-dark-purple-bg"
    src={src}
    width={40}
    fallback={`${firstName.slice(0, 1)}${lastName.slice(0, 1)}`}
  />
);

ProfileImage.propTypes = {
  src: PropTypes.string,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
};

ProfileImage.defaultProps = {
  src: '',
};

export default ProfileImage;
