import { gql } from '@apollo/client';
import { mergeAndDedupeRefsById } from '../utils/object';

export const consultantsTypePolicy = {
  keyArgs: false,
  merge: mergeAndDedupeRefsById,
};

const register = gql`
mutation (
  $address: String!,
  $school: String,
  $college: String,
  $collegeCourseId: String,
  $dateOfBirth: String!,
  $email: String!,
  $ethnicityId: String!,
  $firstName: String!,
  $freeSchoolMeals: Boolean!,
  $genderId: String!,
  $interestsId: [String!],
  $lastName: String!,
  $lat: String!,
  $lng: String!,
  $password: String!,
  $referralId: String!,
  $sexualOrientationId: String!,
  $siblings: [SiblingsInput!],
  $university: String,
  $universityCourseId: String,
) {
  signupConsultant (input: {
    address: $address,
    college: $college,
    collegeCourseId: $collegeCourseId,
    dateOfBirth: $dateOfBirth,
    email: $email,
    ethnicityId: $ethnicityId,
    firstName: $firstName,
    freeSchoolMeals: $freeSchoolMeals,
    genderId: $genderId,
    interestsId: $interestsId,
    lastName: $lastName,
    lat: $lat,
    lng: $lng,
    password: $password,
    referralId: $referralId,
    school: $school,
    sexualOrientationId: $sexualOrientationId,
    siblings: $siblings,
    university: $university,
    universityCourseId: $universityCourseId,
  }) {
    consultant {
      id,
    }
  }
}
`;

const get = gql`
  query (
    $consultantId: String!,
  ) {
    consultant (input: {
      consultantId: $consultantId,
    }) {
      address {
        address,
        lat,
        lng,
      },
      college,
      collegeCourse {
        id,
        title,
      },
      consultantInterests {
        interest {
          name,
          emoji,
        },
      },
      createdAt,
      dateOfBirth,
      ethnicity {
        id,
        title,
      },
      freeSchoolMeals,
      gender {
        id,
        title,
      },
      id,
      referral {
        title,
      }
      school,
      sexualOrientation {
        id,
        title,  
      },
      siblings {
        dateOfBirth,
      },
      stripeAccount {
        verificationRequired,
        verificationUrl,
      },
      university,
      universityCourse {
        id,
        title,
      },
      user {
        block,
        email,
        firstName,
        lastName,
        profileImage,
        createdAt,
      }
    }
  }
`;

const logIn = gql`
  mutation ($email: String!, $password: String!) {
    loginConsultant (input: {
      email: $email,
      password: $password,
    }) {
      jwt,
      consultant {
        id,
      },
    }
  }
`;

const list = gql`
query (
  $offset: Int!,
  $limit: Int!,
  $search: String = "",
) {
  consultants (input: {
    pagination: {
      limit: $limit,
      offset: $offset,
    },
    filter: {
      search: $search,
    }
  }) {
    address {
      address,
      lat,
      lng,
    },
    college,
    collegeCourse {
      id,
      title
    },
    consultantInterests {
      id
      interest {
        id
        name,
        emoji,
      },
    },
    createdAt,
    dateOfBirth,
    ethnicity {
      id,
      title,
    },
    freeSchoolMeals,
    gender {
      id,
      title,
    },
    id,
    insightCount,
    referral {
      id,
      title,
    }
    school,
    sexualOrientation {
      id,
      title,  
    },
    siblings {
      dateOfBirth,
    },
    university,
    universityCourse {
      id,
      title,
    },
    user {
      block,
      email,
      firstName,
      lastName,
      profileImage,
      createdAt,
    }
  }
}
`;

const update = gql`
mutation (
  $id: String!,
  $address: String,
  $block: Boolean!,
  $school: String,
  $college: String,
  $collegeCourseId: String,
  $dateOfBirth: String!,
  $email: String!,
  $ethnicityId: String!,
  $firstName: String!,
  $freeSchoolMeals: Boolean!,
  $genderId: String!,
  $interestsId: [String!],
  $lastName: String!,
  $lat: String,
  $lng: String,
  $profileImage: String,
  $sexualOrientationId: String!,
  $siblings: [SiblingsInput!],
  $university: String,
  $universityCourseId: String,
) {
  editConsultant(input: {
    consultantId: $id,
    address: $address,
    block: $block,
    school: $school,
    college: $college,
    collegeCourseId: $collegeCourseId,
    dateOfBirth: $dateOfBirth,
    email: $email,
    ethnicityId: $ethnicityId,
    firstName: $firstName,
    freeSchoolMeals: $freeSchoolMeals,
    genderId: $genderId,
    interestsId: $interestsId,
    lastName: $lastName,
    lat: $lat,
    lng: $lng,
    profileImage: $profileImage,
    sexualOrientationId: $sexualOrientationId,
    siblings: $siblings,
    university: $university,
    universityCourseId: $universityCourseId,
  }) {
    id,
  }
}
`;

const statistics = gql`
query (
  $consultantId: String!,
) {
  statistics(input: {
    consultantId: $consultantId,
  }) {
    insightStreak,
    topConsultant,
    totalEarned,
  }
}
`;

export default {
  logIn, register, list, get, update, statistics,
};
