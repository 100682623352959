import PropTypes from 'prop-types';
import { useRecoilState } from 'recoil';
import { useEffect, useState } from 'react';
import useLocalStorage from 'react-use-localstorage';
import { IntlProvider } from 'react-intl';
import { Settings as DateTimeSettings } from 'luxon';
import langpacks, { flattenLangpack } from '../utils/locale';
import { localeState } from '../atoms/locale';
import { DEFAULT_LOCALE, LOCALES } from '../constants/locale';
import { LOCALE as LOCALE_SESSION_KEY } from '../constants/session';
import Loader from './Loader';

const Intl = ({ children, loaderContainerClassName }) => {
  const [locale, setLocale] = useRecoilState(localeState);
  const [langpack, setLangpack] = useState({});
  const [storedLocale, persistLocale] = useLocalStorage(LOCALE_SESSION_KEY, DEFAULT_LOCALE);

  useEffect(() => {
    if (
      storedLocale !== DEFAULT_LOCALE
      && LOCALES.includes(storedLocale)
    ) {
      setLocale(storedLocale);
    }
  }, []);

  useEffect(() => {
    (async () => {
      setLangpack(flattenLangpack(await langpacks[locale]()));
    })();
    persistLocale(locale);
    DateTimeSettings.defaultLocale = locale;
  }, [locale]);

  return (
    <IntlProvider
      locale={locale}
      messages={langpack}
    >
      {
        Object.keys(langpack).length === 0
          ? (
            <div className={loaderContainerClassName}>
              <Loader />
            </div>
          )
          : children
      }
    </IntlProvider>
  );
};

Intl.propTypes = {
  children: PropTypes.node,
  loaderContainerClassName: PropTypes.string,
};

Intl.defaultProps = {
  children: null,
  loaderContainerClassName: '',
};

export default Intl;
