import { FormattedMessage } from 'react-intl';
import { Link, useHistory } from 'react-router-dom';
import { Fragment } from 'react';
import PropTypes from 'prop-types';
import styles from './Nav.module.scss';
import { ConsultantPaths as Paths } from '../../../constants/paths';
import { ReactComponent as ImagenLogo } from '../../../assets/imagen.svg';
import { ReactComponent as Arrow } from '../../../assets/arrow.svg';
import Button, { LIGHT_ALT, OUTLINE, TRANSLUCENT } from '../../common/Button/Button';
import useSession from '../../../hooks/useSession';
import UserInfo from '../../common/UserInfo';
import ConsultantService from '../../../service/consultant';
import useAuthentication from '../../../hooks/useAuthentication';
import PageNav from './PageNav/PageNav';
import Footer from './Footer';

const Nav = ({
  children, showBack, showFooter, className,
}) => {
  const { goBack } = useHistory();
  const { userId: consultantId } = useSession();
  const isAuthenticated = useAuthentication();

  return (
    <Fragment>
      <div className={`${className} container-fluid p-4`}>
        <div className="d-none d-md-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <ImagenLogo className={`${styles.logo} my-1`} />
            <Button
              className={`space-letters ml-5 ${!showBack && 'hidden'}`}
              variant={OUTLINE}
              onClick={goBack}
            >
              <Arrow className={`${styles.arrow} mr-2`} />
              <FormattedMessage id="nav.back" />
            </Button>
          </div>
          {
            isAuthenticated
              ? (
                <Fragment>
                  <PageNav className="pr-5" />
                  <UserInfo
                    query={ConsultantService.get}
                    getDataFromResult={({ consultant: { user } }) => user}
                    variables={{ consultantId }}
                  />
                </Fragment>
              )
              : (
                <Link
                  className="text-decoration-none"
                  to={Paths.createAccount()}
                >
                  <Button
                    variant={LIGHT_ALT}
                    className="space-letters"
                  >
                    <FormattedMessage id="nav.createAccount" />
                  </Button>
                </Link>
              )
          }
        </div>
        <div className="d-flex d-md-none align-items-center justify-content-between">
          <Button
            className={`p-2 ${!showBack && 'hidden'}`}
            variant={TRANSLUCENT}
            isIconOnly={true}
            onClick={goBack}
          >
            <Arrow className={styles.arrow} />
          </Button>
          <ImagenLogo className={`${styles.logo} ml-4 my-1`} />
          {
            isAuthenticated
              ? (
                <UserInfo
                  query={ConsultantService.get}
                  getDataFromResult={({ consultant: { user } }) => user}
                  variables={{ consultantId }}
                />
              )
              : <div className="mx-3" />
          }
        </div>
      </div>
      {children}
      {showFooter && <Footer />}
    </Fragment>
  );
};

Nav.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  showBack: PropTypes.bool,
  showFooter: PropTypes.bool,
};

Nav.defaultProps = {
  className: '',
  children: null,
  showBack: true,
  showFooter: true,
};

export default Nav;
