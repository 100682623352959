import { Route, Switch } from 'react-router-dom';
import styles from './client/ImagenClient.module.scss';
import {
  ADMIN_APP_PREFIX,
  CLIENT_APP_PREFIX, LOGIN_PREFIX, RESET_PASSWORD_PREFIX,
} from '../constants/paths';
import ImagenClient from './client/ImagenClient';
import ImagenConsultant from './consultant/ImagenConsultant';
import Intl from './Intl';
import ImagenAuth from './auth/ImagenAuth';

const Imagen = () => (
  <Intl loaderContainerClassName={styles.container}>
    <div className="min-vh-100 d-flex flex-column">
      <Switch>
        <Route path={`/${CLIENT_APP_PREFIX}`}>
          <ImagenClient />
        </Route>
        <Route path={`/${ADMIN_APP_PREFIX}`}>
          <ImagenClient />
        </Route>
        <Route path={`/${RESET_PASSWORD_PREFIX}`}>
          <ImagenAuth />
        </Route>
        <Route path={`/${LOGIN_PREFIX}`}>
          <ImagenAuth />
        </Route>
        <Route path="/">
          <ImagenConsultant />
        </Route>
      </Switch>
    </div>
  </Intl>
);

export default Imagen;
