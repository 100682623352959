import PropTypes from 'prop-types';
import styles from './ImageIcon.module.scss';

export const SQUIRCLE = 'squircle';
export const CIRCLE = 'circle';

const ImageIcon = ({
  src, className, variant, width, fallback,
}) => (
  src
    ? (
      <div
        className={`${styles.image} ${styles[`image-${variant}`]} ${className} flex-shrink-0`}
        style={{
          backgroundImage: `url(${src})`,
          width: `${width}px`,
          height: `${width}px`,
        }}
      />
    )
    : (
      <div
        className={`${styles.fallback} d-flex align-items-center justify-content-center text-white imagen-dark-purple-bg font-size-16 py-2 text-uppercase flex-shrink-0`}
        style={{
          width: `${width}px`,
          height: `${width}px`,
        }}
      >
        {fallback}
      </div>
    )
);

ImageIcon.propTypes = {
  src: PropTypes.string,
  fallback: PropTypes.string,
  className: PropTypes.string,
  variant: PropTypes.oneOf([SQUIRCLE, CIRCLE]),
  width: PropTypes.number,
};

ImageIcon.defaultProps = {
  fallback: '',
  className: '',
  src: '',
  variant: SQUIRCLE,
  width: 35,
};

export default ImageIcon;
