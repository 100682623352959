import { gql } from '@apollo/client';

const get = gql`
query ($userId: String!) {
  user (input: {
    userId: $userId,
  }) {
    id,
    email,
    firstName,
    lastName,
    profileImage,
  }
}
`;

const requestPasswordResetEmail = gql`
  mutation (
    $email: String!,
    $type: String!,
  ) {
    forgotPassword (input: {
      email: $email,
      type: $type,
    }) {
      success,
    }
  }
`;

const resetPassword = gql`
  mutation (
    $email: String!,
    $password: String!,
    $token: String!,
    $type: String!,
  ) {
    resetPassword (input: {
      email: $email,
      password: $password,
      token: $token,
      type: $type,
    }) {
      success,
    }
  }
`;

export default { get, requestPasswordResetEmail, resetPassword };
