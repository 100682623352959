import useAuthentication from '../../../hooks/useAuthentication';
import PageNav from './PageNav/PageNav';

const Footer = () => {
  const isAuthenticated = useAuthentication();

  if (!isAuthenticated) {
    return null;
  }

  return (
    <div className="d-block d-md-none bg-white border-top">
      <PageNav
        className="container"
        showProfile={true}
      />
    </div>
  );
};

export default Footer;
