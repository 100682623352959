import { FormattedMessage } from 'react-intl';
import { useEffect, useState } from 'react';
import useLocalStorage from 'react-use-localstorage';
import { useHistory, useParams } from 'react-router-dom';
import SlideDown from 'react-slidedown';
import classNames from 'classnames';
import styles from './LoginContainer.module.scss';
import { TOKEN, USER_ID, USER_TYPE } from '../../constants/session';
import ForgotPassword from './ForgotPassword';
import Login from './Login';
import { ReactComponent as ChevronDown } from '../../assets/chevron-down.svg';
import LoginWrapper from './LoginWrapper/LoginWrapper';
import useAuthentication from '../../hooks/useAuthentication';
import { getUserTypeConfig, UserType } from '../../constants/userType';

const LoginContainer = () => {
  const history = useHistory();
  const { userType } = useParams();
  const [showResetPassword, setShowResetPassword] = useState(false);
  const setToken = useLocalStorage(TOKEN)[1];
  const setUserId = useLocalStorage(USER_ID)[1];
  const [sessionUserType, setUserType] = useLocalStorage(USER_TYPE);
  const isAuthenticated = useAuthentication();
  const isConsultant = userType === UserType.CONSULTANT;

  const onToggleResetPassword = () => setShowResetPassword(!showResetPassword);

  useEffect(() => {
    if (isAuthenticated) {
      const { landing } = getUserTypeConfig(sessionUserType);
      history.push(landing);
    } else {
      setToken(null);
      setUserId(null);
      setUserType(null);
    }
  }, []);

  return (
    <LoginWrapper>
      <div />
      <SlideDown transitionOnAppear={false}>
        {!showResetPassword && <Login />}
      </SlideDown>
      <button
        className={classNames(
          'btn-reset font-size-14 text-center d-flex align-items-center', {
            'text-white': isConsultant,
            'imagen-gray-500': !isConsultant,
          })}
        type="button"
        onClick={onToggleResetPassword}
      >
        <FormattedMessage id="auth.forgot.prompt" />
        <ChevronDown className={`${styles.chevron} ${styles[`chevron-${userType}`]} ${showResetPassword && styles.open}`} />
      </button>
      <SlideDown>
        {showResetPassword && (
        <ForgotPassword />
        )}
      </SlideDown>
    </LoginWrapper>
  );
};

export default LoginContainer;
