export const CLIENT_APP_PREFIX = 'client';
export const ADMIN_APP_PREFIX = 'admin';

const admin = () => `/${ADMIN_APP_PREFIX}`;
const brief = () => `/${CLIENT_APP_PREFIX}/dashboard/brief`;
const clientDashboard = () => `/${CLIENT_APP_PREFIX}/dashboard`;
const editBrief = (id) => `/${CLIENT_APP_PREFIX}/dashboard/brief/${id}`;
const insights = (userType) => `/${userType}/insights`;
const settings = () => `/${CLIENT_APP_PREFIX}/settings`;
const settingPanel = (panel) => `/${CLIENT_APP_PREFIX}/settings/${panel}`;

export const ClientPaths = {
  admin, brief, dashboard: clientDashboard, editBrief, insights, settings, settingPanel,
};

const createAccount = () => '/create';
const accountCreated = () => '/created';
const consultantDashboard = () => '/dashboard';
const brand = (brandId) => `/brand/${brandId}`;
const viewBrief = (briefId) => `/brief/${briefId}`;

export const ConsultantPaths = {
  createAccount, accountCreated, dashboard: consultantDashboard, brand, viewBrief,
};

export const LOGIN_PREFIX = 'login';
export const RESET_PASSWORD_PREFIX = 'reset-password';

const resetPassword = (userType, token) => `/${RESET_PASSWORD_PREFIX}/${userType}/${token}`;
const login = () => `/${LOGIN_PREFIX}`;
const loginUserType = (userType) => `/${LOGIN_PREFIX}/${userType}`;

export const AuthPaths = {
  login, loginUserType, resetPassword,
};
