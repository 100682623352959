import { useMutation } from '@apollo/client';
import useLocalStorage from 'react-use-localstorage';
import OneSignal from 'react-onesignal';
import { getUserTypeConfig, UserType } from '../constants/userType';
import { TOKEN, USER_ID, USER_TYPE } from '../constants/session';
import { AuthPaths as Paths } from '../constants/paths';

const useLogin = (userType) => {
  const { mutation, dataKey, idKey } = getUserTypeConfig(userType);
  const [logIn, { loading }] = useMutation(mutation);
  const setToken = useLocalStorage(TOKEN)[1];
  const setUserId = useLocalStorage(USER_ID)[1];
  const setUserType = useLocalStorage(USER_TYPE)[1];

  return {
    loading,
    logIn: async (email, password) => {
      const { data: { [dataKey]: { jwt, [idKey]: { id } } } } = await logIn({
        variables: { email, password },
      });

      if (userType === UserType.CONSULTANT) {
        // noinspection ES6MissingAwait
        OneSignal.sendTag('consultantId', id);
      }
      setToken(jwt);
      setUserId(id);
      setUserType(userType);
      window.location.href = Paths.loginUserType(userType);
    },
  };
};

export default useLogin;
