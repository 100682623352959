import { gql } from '@apollo/client';
import { mergeAndDedupeRefsById } from '../utils/object';

export const briefsTypePolicy = {
  keyArgs: false,
  merge: mergeAndDedupeRefsById,
  // read: (briefs, { args, readField }) => {
  //   const liveStatus = args?.input?.filter?.live?.eq;
  //   if (
  //     briefs === undefined
  //   ) {
  //     return briefs;
  //   }
  //   if (liveStatus !== undefined) {
  //     return briefs.filter((brief) => readField('live', brief) === liveStatus);
  //   }
  //   return briefs;
  // },
};

const listLiveByBrandId = gql`
query (
  $brandId: String!,
  $live: Boolean!,
  $offset: Int!,
  $limit: Int!,
) {
  briefs(input: {
    filter: {
      brandId: {
        eq: $brandId,
      }
      live: {
        eq: $live,
      },
    },
    pagination: {
      limit: $limit,
      offset: $offset,
    },
  }) {
    id,
    submitted,
    title,
    deadline,
    image,
    live,
    insightCount,
    viewCount,
  }
}
`;

const listDeadlineLiveByBrandId = gql`
  query (
    $brandId: String!,
    $live: Boolean!,
    $deadline: String!,
    $offset: Int!,
    $limit: Int!,
  ) {
    briefs(input: {
      filter: {
        brandId: {
          eq: $brandId,
        }
        live: {
          eq: $live,
        },
        deadline: {
          gte: $deadline,
        }
      },
      pagination: {
        limit: $limit,
        offset: $offset,
      },
    }) {
      id,
      submitted,
      title,
      deadline,
      image,
      live,
      insightCount,
      viewCount,
    }
  }
`;

const listByBrandId = gql`
query (
    $brandId: String!,
    $offset: Int!,
    $limit: Int!,
) {
  briefs(input: {
    filter: {
        brandId: {
            eq: $brandId,
        },
    },
    pagination: {
        limit: $limit,
        offset: $offset,
    },
  }) {
    id,
    title,
    deadline,
    image,
    live,
    insightCount,
    viewCount,
  }
}
`;

const list = gql`
  query (
    $offset: Int!,
    $limit: Int!,
  ) {
    briefs(input: {
      pagination: {
        limit: $limit,
        offset: $offset,
      },
    }) {
      id,
      title,
      deadline,
      image,
      live,
      insightCount,
      viewCount,
    }
  }
`;

const get = gql`
query ($briefId: String!) {
  brief (input: {
    briefId: $briefId,
  }) {
    id,
    deadline,
    details,
    example,
    image,
    live,
    cancelled,
    submitted,
    ethnicity,
    location,
    gender,
    minAge,
    maxAge,
    questions {
      id,
      title,
      subtitle,
      type,
      index,
      minCharacters,
      maxCharacters,
      allowAttachments,
    }
    summary,
    title,
    brand {
      bio,
      name,
      coverImage,
      logoImage,
      insightLimit,
    }
  }
}
`;

const add = gql`
mutation (
  $brandId: String!,
  $deadline: String!,
  $details: String!,
  $example: String,
  $image: String,
  $questions: [QuestionInput!]!,
  $summary: String,
  $title: String!,
  $ethnicity: String,
  $gender: String,
  $minAge: Int,
  $maxAge: Int,
  $location: String,
) {
  addBrief (input: {
    brandId: $brandId,
    deadline: $deadline,
    details: $details,
    example: $example,
    image: $image,
    live: false,
    questions: $questions,
    summary: $summary,
    title: $title,
    ethnicity: $ethnicity,
    location: $location,
    gender: $gender,
    minAge: $minAge,
    maxAge: $maxAge,
  }) {
    id,
    deadline,
    details,
    example,
    image,
    live,
    questions {
      id,
      title,
      subtitle,
      type,
      index,
      minCharacters,
      maxCharacters,
      allowAttachments,
    }
    summary,
    title,
    ethnicity,
    location,
    gender,
    minAge,
    maxAge,
  }
}
`;

const update = gql`
mutation (
  $briefId: String!,
  $cancelled: Boolean!,
  $deadline: String!,
  $details: String!,
  $example: String,
  $image: String,
  $live: Boolean!,
  $questions: [EditQuestionInput!]!,
  $summary: String,
  $title: String!,
  $ethnicity: String,
  $gender: String,
  $minAge: Int,
  $maxAge: Int,
  $location: String,
) {
  editBrief (input: {
    briefId: $briefId,
    cancelled: $cancelled,
    deadline: $deadline,
    details: $details,
    example: $example,
    image: $image,
    live: $live,
    questions: $questions,
    summary: $summary,
    title: $title,
    ethnicity: $ethnicity,
    location: $location,
    gender: $gender,
    minAge: $minAge,
    maxAge: $maxAge,
  }) {
    id,
    cancelled,
    deadline,
    details,
    example,
    image,
    live,
    summary,
    title,
    ethnicity,
    location,
    gender,
    minAge,
    maxAge,
    questions {
      id,
      title,
      subtitle,
      type,
      index,
      minCharacters,
      maxCharacters,
      allowAttachments,
    }
  }
}
`;

const view = gql`
  mutation ($briefId: String!) {
    viewBrief(input: {
      briefId: $briefId,
    }) {
      id,
    },
  }
`;

const getAcceptBrief = gql`
  query ($briefId: String!) {
    getAcceptBrief (input: {
      briefId: $briefId,
    }) {
      id, status, createdAt
    }
  }
`;

const getAcceptBriefs = gql`
  query ($briefId: String!) {
    getAcceptBriefs (input: {
      briefId: $briefId,
    }) {
      id, status, createdAt
    }
  }
`;

const getExpired = gql`
  query {
    getExpired {
      id, status, createdAt
    }
  }
`;

const acceptBrief = gql`
  mutation (
    $briefId: String!,
  ) {
    acceptBrief (input: {
      briefId: $briefId,
    }) {
      id, status, createdAt
    }
  }
`;

const joinWaitingList = gql`
  mutation (
    $briefId: String!,
  ) {
    joinWaitingList (input: {
      briefId: $briefId,
    }) {
      id, status, createdAt
    }
  }
`;

const acknowledgeExpired = gql`
  mutation (
    $ids: [String!]!,
  ) {
    acknowledgeExpired (input: {
      ids: $ids,
    }) {
      id, status, createdAt
    }
  }
`;

export default {
  listLiveByBrandId,
  listDeadlineLiveByBrandId,
  listByBrandId,
  add,
  update,
  get,
  view,
  list,
  acceptBrief,
  getAcceptBrief,
  getAcceptBriefs,
  joinWaitingList,
  getExpired,
  acknowledgeExpired,
};
