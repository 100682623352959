import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { Link, useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import pattern from '../../../assets/pattern.jpg';
import styles from './LoginWrapper.module.scss';
import { AuthPaths } from '../../../constants/paths';
import { UserType } from '../../../constants/userType';
import Nav from '../../consultant/Nav/Nav';
import TopNav, { MINIMAL_DARK, MINIMAL } from '../../client/Nav/TopNav/TopNav';

const LoginWrapper = ({ children }) => {
  const { userType } = useParams();
  const isConsultant = userType === UserType.CONSULTANT;

  return (
    <div
      className={`${styles[`background-${userType}`]} flex-grow-1 d-flex flex-column justify-content-between`}
      style={{ backgroundImage: !isConsultant && `url(${pattern})` }}
    >
      <div className="w-100">
        {userType === UserType.CONSULTANT && <Nav showFooter={false} />}
        {userType === UserType.ADMIN && (
          <TopNav
            isAdmin={true}
            variant={MINIMAL_DARK}
          />
        )}
        {userType === UserType.CLIENT && (
          <TopNav
            variant={MINIMAL}
            right={(
              <Link
                to={AuthPaths.loginUserType(UserType.ADMIN)}
                className="font-size-14 mr-3"
              >
                <FormattedMessage id="auth.login.asAdmin" />
              </Link>
            )}
          />
        )}
      </div>
      <div className="container h-100 d-flex justify-content-center py-5">
        <Form>
          <div
            className={`${styles.container} d-flex flex-column justify-content-center align-items-center`}
          >
            {children}
          </div>
        </Form>
      </div>
      <div className="my-5" />
    </div>
  );
};

LoginWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
};

LoginWrapper.defaultProps = {
  children: null,
};

export default LoginWrapper;
