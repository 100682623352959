import { useLocation } from 'react-router-dom';
import { ReactComponent as HomeIcon } from '../../../../assets/home.svg';
// import { ReactComponent as UserIcon } from '../../../assets/user.svg';
import { ReactComponent as InboxIcon } from '../../../../assets/inbox.svg';
// import { ReactComponent as PieIcon } from '../../../assets/pie.svg';
import { ReactComponent as CogIcon } from '../../../../assets/cog.svg';
import NavButton from '../../../common/NavButton/NavButton';
import TooltipHint from '../../../common/NavButton/TooltipHint';
import { ClientPaths as Paths } from '../../../../constants/paths';
import styles from './LeftNav.module.scss';
import { UserType } from '../../../../constants/userType';
import useSession from '../../../../hooks/useSession';

const items = {
  [UserType.CLIENT]: {
    [Paths.dashboard()]: HomeIcon,
    // consultants: UserIcon,
    [Paths.insights(UserType.CLIENT)]: InboxIcon,
    // analytics: PieIcon,
    [Paths.settings()]: CogIcon,
  },
  [UserType.ADMIN]: {
    [Paths.admin()]: HomeIcon,
    [Paths.insights(UserType.ADMIN)]: InboxIcon,
  },
};

const LeftNav = () => {
  const { pathname } = useLocation();
  const { userType } = useSession();

  return (
    <div className={`${styles.leftNav} border-right d-flex flex-column bg-white`}>
      <div className="position-sticky d-flex flex-column">
        {Object.keys(items[userType]).map((itemPath) => (
          <TooltipHint
            key={itemPath}
            message={`${itemPath.split('/')[2]}.title`}
          >
            <NavButton
              path={itemPath}
              Icon={items[userType][itemPath]}
              isCurrent={itemPath === pathname}
            />
          </TooltipHint>
        ))}
      </div>
    </div>
  );
};

export default LeftNav;
