import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import Tooltip from '@tippyjs/react';

const TooltipHint = ({ message, children }) => (
  <Tooltip
    arrow={false}
    placement="right"
    theme="imagen-purple"
    content={(
      <FormattedMessage id={message} />
    )}
  >
    {children}
  </Tooltip>
);

TooltipHint.propTypes = {
  message: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default TooltipHint;
