import AdminService from '../service/admin';
import ClientService from '../service/client';
import { ClientPaths, ConsultantPaths } from './paths';
import ConsultantService from '../service/consultant';

export const UserType = {
  CLIENT: 'client',
  CONSULTANT: 'consultant',
  ADMIN: 'admin',
};

export const getUserTypeConfig = (userType) => ({
  [UserType.ADMIN]: {
    mutation: AdminService.logIn,
    landing: ClientPaths.admin(),
    dataKey: 'loginAdmin',
    idKey: 'user',
  },
  [UserType.CLIENT]: {
    mutation: ClientService.logIn,
    landing: ClientPaths.dashboard(),
    dataKey: 'loginClient',
    idKey: 'client',
  },
  [UserType.CONSULTANT]: {
    mutation: ConsultantService.logIn,
    landing: ConsultantPaths.dashboard(),
    dataKey: 'loginConsultant',
    idKey: 'consultant',
  },
}[userType]);
